import(/* webpackMode: "eager" */ "/usr/src/app/app/globals.css");
import(/* webpackMode: "eager" */ "/usr/src/app/components/providers.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components/sidebar-items.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components/sidebar-mobile.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components/sidebar-toggle.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components/ui/icons.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/app-router-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/hooks-client-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/server-inserted-html.shared-runtime.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.2.1_next@14.1.3/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.3_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.2.1_next@14.1.3/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
import(/* webpackMode: "eager" */ "/usr/src/app/public/wiz/WizTalk1.png")